<ds-app-wrapper [menu]="menu" disablePageHeadlines>
  <router-outlet></router-outlet>
</ds-app-wrapper>
<ng-template #dialogTemplate>
  <h1 mat-dialog-title>
    {{ 'brochure_service' | translate }}
  </h1>
  <div mat-dialog-content>
    <p>
      {{ 'prospectServiceInfo' | translate }}
      <a href="mailto:promotion@palfinger.com"> promotion&#64;palfinger.com </a>
    </p>
  </div>
  <div mat-dialog-actions>
    <button mat-raised-button color="accent" matDialogClose>
      {{ 'general.confirm' | translate }}
    </button>
  </div>
</ng-template>
