import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { DsBreadcrumbsModule } from '@design-system/components/breadcrumbs';
import { DsLoadingModule } from '@design-system/components/loading';
import {
  AppWrapperModule,
  DS_APP_WRAPPER_CONFIG,
  DsAppWrapperConfig,
} from '@design-system/feature/app-wrapper-v2';
import { SharedLibFeatTranslationModule } from '@features/translation';
import { SharedLibFeaturesFreshdeskWidgetModule } from '@paldesk/shared-lib/features/freshdesk-widget';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { appRoutes } from './app.routing';
import { CoreModule } from './core.module';
import { SharedModule } from './shared';

@NgModule({
  declarations: [AppComponent],
  imports: [
    CoreModule,
    SharedModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes),
    SharedLibFeatTranslationModule,
    HttpClientModule,
    AppWrapperModule.forRoot([
      {
        provide: DS_APP_WRAPPER_CONFIG,
        useFactory: (): DsAppWrapperConfig => ({
          environment: environment.environment,
          apiBasePath: environment.palfinger_api_management,
          isNativeApp: false,
          paldeskBasePath: environment.LINKS.PALDESK_BASEPATH,
          paldeskSupportPath: environment.LINKS.STATUS_PALDESK_PAGE,
          appInsightsRole: 'FE: Brochure Service',
          appGatewayBasePath: environment.palfinger_app_gateway,
          appName: 'Brochure Service',
          appHelpPath: environment.LINKS.APP_HELP_PATH,
          appNewsPath: environment.LINKS.APP_NEWS_PATH,
          statusBannerProductsToCheck: [],
        }),
      },
    ]),
    DsBreadcrumbsModule,
    DsLoadingModule,
    SharedLibFeaturesFreshdeskWidgetModule.forRoot({
      widgetId: 15000000344,
    }),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
