import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Routes } from '@angular/router';
import { APPS } from '@config';
import { AuthGuard, AuthPermissions } from '@features/auth';

export const appRoutes: Routes = [
  {
    path: '',
    data: {
      permissions: {
        roles: [
          APPS.BROCHURE_SERVICE.ROLES.EMPLOYEE,
          APPS.BROCHURE_SERVICE.ROLES.EMPLOYEE_GERMANY,
          APPS.BROCHURE_SERVICE.ROLES.USER,
        ],
      } as AuthPermissions,
    },
    canActivate: [
      (next: ActivatedRouteSnapshot) => inject(AuthGuard).canActivate(next),
    ],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'overview',
      },
      {
        path: 'overview',
        loadChildren: () =>
          import('./overview/overview.module').then((m) => m.OverviewModule),
      },
      {
        path: 'cart',
        loadChildren: () =>
          import('./cart/cart.module').then((m) => m.CartModule),
      },
    ],
  },
  {
    path: 'callback',
    children: [],
  },
  {
    path: 'special',
    loadChildren: () =>
      import('@design-system/feature/special-pages').then(
        (m) => m.DsSpecialPagesModule,
      ),
  },
  {
    path: '**',
    redirectTo: 'special',
  },
];
