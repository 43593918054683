import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Menu } from '@design-system/feature/app-wrapper-v2';
import { Subject, takeUntil } from 'rxjs';
import { CartService } from './shared';

@Component({
  selector: 'bs-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild('dialogTemplate', { static: true })
  dialogTemplate: TemplateRef<any>;

  private readonly destroy$ = new Subject<void>();

  menu: Menu = {
    title: 'brochure_service',
    titleIcon: 'photo_album',
    navigation: [
      {
        icon: 'collections',
        title: 'search',
        routerLink: '/overview',
      },
      {
        icon: 'shopping_cart',
        title: 'shoppingCart',
        routerLink: '/cart',
      },
    ],
  };

  constructor(
    private dialog: MatDialog,
    private cartService: CartService,
  ) {
    this.cartService
      .cartSize()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (count) => {
          const target = this.menu.navigation.find(
            (n) => n.title === 'shoppingCart',
          );
          if (target) {
            target.badge = count;
          }
        },
      });
  }

  ngOnInit(): void {
    /**
     * Check if user already confirmed the initial message. Otherwise show him the message.
     */
    if (localStorage.getItem('brochureServiceTermsAccepted') !== 'true') {
      const dialogRef = this.dialog.open(this.dialogTemplate, {
        disableClose: true,
      });
      dialogRef
        .afterClosed()
        .subscribe(() =>
          localStorage.setItem('brochureServiceTermsAccepted', 'true'),
        );
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
